import { colorBlueLight, colorBlueMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import classnames from "classnames";
import type { HTMLProps, VoidFunctionComponent } from "react";

interface Props
  extends Pick<
    HTMLProps<HTMLInputElement>,
    | "checked"
    | "defaultChecked"
    | "disabled"
    | "id"
    | "name"
    | "onChange"
    | "onBlur"
    | "value"
  > {
  className?: string;
}

/**
 * A generic radio button input. Implements a subset of
 * `<input type="radio" />` for use with Formik.
 */
const RadioButton: VoidFunctionComponent<Props> = ({
  checked,
  className,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  value,
}) => {
  return (
    <>
      <input
        checked={checked}
        className={classnames([className, checked ? "checked" : ""])}
        css={css`
          :enabled {
            aspect-ratio: 1 / 1;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 100%;
            content: "";
            height: 1rem;
            transition: border-color 0.125s ease-in;
            width: 1rem;
          }

          :after {
            border-radius: 100%;
            content: "";
            display: flex;
            height: 10px;
            transition: background 0.125s ease-in;
            width: 10px;
          }

          :not(:disabled):hover {
            border-color: ${colorBlueMedium};
          }

          :checked,
          .checked {
            border: 1px solid ${colorBlueMedium};
            background: ${colorBlueMedium};
          }

          .checked:after {
            border: 1px solid ${colorBlueMedium};
            background: ${colorBlueMedium};
          }

          :disabled {
            border-color: ${colorBlueLight};
          }

          :after:disabled:checked {
            background: ${colorBlueLight};
          }
        `}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type={"radio"}
        value={value}
      />
    </>
  );
};

export default RadioButton;
