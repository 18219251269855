import { colorBlueMedium, colorSteelLighter } from "@10xdev/design-tokens";

interface QueryInputState {
  borderColor: string;
  hasClearButton: boolean;
}

export enum InputStates {
  /**
   * The query input has focus and the user
   * can enter or is entering a query.
   */
  ACTIVE_FOCUSED = "activeFocused",
  /**
   * The query input is unfocused but there
   * is input remaining from a user interaction.
   */
  ACTIVE_UNFOCUSED = "activeUnfocused",
  /**
   * The default state of the query input.
   * No focus, no user input.
   */
  INACTIVE_UNFOCUSED = "inactiveUnfocused",
}
export const DEBOUNCE_TIME = 200;

export const QUERY_INPUT_STATES: Record<InputStates, QueryInputState> =
  Object.freeze({
    /**
     * The query input has focus and the user
     * can enter or is entering a query.
     */
    [InputStates.ACTIVE_FOCUSED]: {
      borderColor: colorBlueMedium,
      hasClearButton: true,
    },

    /**
     * The query input is unfocused but there
     * is input remaining from a user interaction.
     */
    [InputStates.ACTIVE_UNFOCUSED]: {
      borderColor: colorSteelLighter,
      hasClearButton: true,
    },

    /**
     * The default state of the query input.
     * No focus, no user input.
     */
    [InputStates.INACTIVE_UNFOCUSED]: {
      borderColor: colorSteelLighter,
      hasClearButton: false,
    },
  });
