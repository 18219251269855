import { colorSteelMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useState } from "react";

import Icon from "../Icon";
import Text from "../Text";

const CollapsibleFilterMenu = ({
  children,
  label,
}: {
  children: JSX.Element;
  label: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleClick = () => setIsExpanded(!isExpanded);

  return (
    <li
      css={css`
        list-style: none;
        margin-bottom: 12px;
      `}
    >
      <Text
        as={"h4"}
        css={css`
          margin-bottom: ${isExpanded ? "12px" : "8px"};
          padding-top: 8px;
        `}
        size={"xsmall"}
      >
        <button
          css={css`
            -moz-appearance: none;
            -webkit-appearance: none;
            align-items: center;
            background: none;
            border: none;
            cursor: pointer;
            display: flex;
            height: 24px;
            justify-content: space-between;
            margin: 0;
            margin-bottom: 8px;
            outline: none;
            padding: 0 16px 0 0;
            width: 100%;
          `}
          onClick={handleClick}
          type={"button"}
        >
          <Text as={"span"} size={"xsmall"} weight={"semibold"}>
            {label}
          </Text>
          <Icon
            css={css`
              color: ${colorSteelMedium};
            `}
            size={"12px"}
            source={isExpanded ? "remove" : "plus"}
          />
        </button>
      </Text>

      <div
        css={css`
          display: ${isExpanded ? "unset" : "none"};
        `}
      >
        {children}
      </div>
    </li>
  );
};

export default CollapsibleFilterMenu;
