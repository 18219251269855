import { css } from "@emotion/react";
import type { CSSProperties, FunctionComponent } from "react";
export interface Props {
  alt?: string | null;
  className?: string;
  loading?: "lazy";
  onLoad?: (event: any) => void;
  src: string;
  style?: CSSProperties;
  title?: string;
  width?: number | string;
}

const Image: FunctionComponent<Props> = ({
  alt,
  className = "",
  loading,
  onLoad,
  src,
  style,
  title,
  width,
}) => {
  return (
    <>
      <img
        alt={alt || ""}
        className={className}
        css={css`
          display: block;
          max-width: 100%;
        `}
        loading={loading}
        onLoad={onLoad}
        src={src}
        style={style}
        title={title}
        width={width}
      />
    </>
  );
};

export default Image;
