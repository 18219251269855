import { css } from "@emotion/react";
import type { FormikErrors } from "formik";
import type { FunctionComponent, HTMLProps } from "react";

import ErrorLabel from "./ErrorLabel";

interface Props
  extends Pick<
    HTMLProps<HTMLLabelElement>,
    "children" | "className" | "htmlFor"
  > {
  disabled?: boolean;

  /** An error message indicating failed validation. */
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}

const RadioOrCheckBoxLabel: FunctionComponent<Props> = ({
  children,
  disabled,
  error,
  htmlFor,
}) => {
  return (
    <>
      <label
        css={css`
          align-items: baseline;
          display: flex;
          gap: 8px;
          opacity: ${disabled ? 0.5 : 1};
          pointer-events: ${disabled ? "none" : "all"};
          user-select: none;
        `}
      >
        {children}
      </label>

      {error ? (
        <div
          css={css`
            padding: 0 1px;
          `}
        >
          <ErrorLabel error={error} htmlFor={htmlFor} />
        </div>
      ) : null}
    </>
  );
};

export default RadioOrCheckBoxLabel;
