interface ItemWithLabel {
  label: string;
}

function sortByValues<ItemType extends ItemWithLabel>(
  orderedValues: readonly string[],
  items: ItemType[],
): ItemType[] {
  const sorted = [];

  const toSort = [...items];

  for (const orderedValue of orderedValues) {
    const itemIndex = toSort.findIndex(
      (currentItem) => currentItem.label === orderedValue,
    );

    if (itemIndex < 0) {
      continue;
    }

    const [item] = toSort.splice(itemIndex, 1);

    sorted.push(item);
  }

  return [...sorted, ...toSort];
}

export { sortByValues };
