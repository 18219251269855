import {
  borderRadiusSmall,
  colorBlueLight,
  colorBlueMedium,
  colorRed,
  colorSteelLight,
  colorSteelLightest,
  colorWhite,
  sizeMedium,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import classNames from "classnames";
import type { FormikErrors } from "formik";
import type { HTMLProps, VoidFunctionComponent } from "react";

import Icon from "../Icon";
import HiddenInput from "./HiddenInput";

interface Props
  extends Pick<
    HTMLProps<HTMLInputElement>,
    "disabled" | "id" | "name" | "onBlur" | "onChange" | "name" | "value"
  > {
  /** Indicates whether or not the checkbox is selected. */
  checked: HTMLProps<HTMLInputElement>["defaultChecked"];

  /** An error message indicating failed validation. */
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}

/**
 * A generic checkbox input. Implements a subset of
 * `<input type="checkbox" />` for use with Formik.
 */
const CheckBox: VoidFunctionComponent<Props> = ({
  checked,
  disabled,
  error,
  id,
  name,
  onBlur,
  onChange,
  value,
}) => {
  return (
    <div
      css={css`
        .checked {
          background-color: ${colorBlueMedium};
          border-color: ${colorBlueMedium};
        }

        .error {
          border-color: ${colorRed};
        }

        .disabled {
          border-color: ${colorSteelLight};
          background-color: ${colorSteelLightest};
        }

        .disabled.checked {
          background: ${colorBlueLight};
        }
      `}
    >
      <div
        className={classNames("Box", { checked, disabled, error })}
        css={css`
          align-items: center;
          background-color: ${colorWhite};

          /*
          aspect-ratio won't work on many browsers,
          so set the height and width yourself elsewhere,
          but nice for storybook
          */
          aspect-ratio: 1 / 1;

          // Trying to replicate chrome radio button color, which cannot be changed.
          border-color: #767676;

          @-moz-document url-prefix() {
            // Trying to replicate firefox radio button color, which cannot be changed.
            border-color: #767676;
          }

          border-style: solid;
          border-width: 1px;

          border-radius: ${borderRadiusSmall};
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          min-height: ${sizeMedium};
          min-width: ${sizeMedium};
          position: relative;

          :hover {
            border-color: ${colorBlueMedium};
          }
        `}
      >
        <Icon
          className={classNames("Icon", {
            checked,
            disabled,
          })}
          color={"white"}
          css={css`
            display: ${disabled ? "none" : "unset"};
          `}
          size={"10px"}
          source={"checkmark"}
        />
      </div>

      <HiddenInput
        defaultChecked={checked}
        disabled={disabled}
        // A unique identifier that enables the input to
        // be associated with a label for accessibility.
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={(event) => {
          onChange?.(event);
        }}
        type={"checkbox"}
        // Arbitrary value for submission when checked is true
        value={value}
      />
    </div>
  );
};

export default CheckBox;
