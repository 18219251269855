import { useRouter } from "next/router";
import type { FunctionComponent, HTMLProps } from "react";
import { connectStats } from "react-instantsearch-dom";

import Text from "../Text";

type PassThroughProps = Pick<HTMLProps<HTMLSpanElement>, "style" | "className">;

interface Props extends PassThroughProps {
  // For some reason this isn't exported from the instantsearch types
  nbHits: number;
}

/**
 * A label showing a readout of the current
 * number of search hits, alongside the
 * current search query (if any).
 */
const Stats: FunctionComponent<Props> = ({ nbHits, style, className }) => {
  const { query } = useRouter();

  const searchQuery = query.query;
  const numResults = `${nbHits} results`;
  const nameResults = searchQuery ? ` for "${searchQuery}"` : "";
  const text = numResults + nameResults;

  return (
    <Text
      as={"span"}
      className={className}
      size={"medium"}
      style={style}
      weight={"semibold"}
    >
      {text}
    </Text>
  );
};

export default connectStats(Stats);
