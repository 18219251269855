import { colorBlueMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

interface Props {
  children: ReactNode;
  slash: boolean;
  slashColor?: string;
}

export const getSlashStyles = (slashColor?: string) => css`
  &::before {
    border-top: 1px solid ${slashColor ?? colorBlueMedium};
    content: "";
    position: absolute;
    transform: translate(-48px, 16px) rotate(120deg);
    transform-origin: 0% 0%;
    width: 300px;
  }
`;

const Slash = ({ children, slash = true, slashColor }: Props) => {
  return (
    <>
      {slash ? (
        <div css={getSlashStyles(slashColor)}>{children}</div>
      ) : (
        children
      )}
    </>
  );
};

export default Slash;
