import type { RefinementListProvided } from "react-instantsearch-core";

export enum InputType {
  Checkbox = "checkbox",
  RadioButton = "radioButton",
}

export interface Facet {
  attribute: string;
  inputType?: InputType;
  label: string;
  order?: string[];
}

export interface GenericItem {
  count: number;
  isRefined: boolean;
  label: string;
  value: string | string[];
}

export type Facets = Array<Facet>;

export type RefinementListItem = RefinementListProvided["items"][number];

export type PlatformRefinementListItem = RefinementListItem & {
  platform: string;
};
