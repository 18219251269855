import type { ObjectWithObjectID } from "@algolia/client-search";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import type { Hit, InfiniteHitsProvided } from "react-instantsearch-core";
import { connectInfiniteHits } from "react-instantsearch-dom";

import Intersection from "../Intersection";
import { useHitsFirstReceived, useInfiniteScroll } from "./hooks";

// Can't pass more sophisticated generics due to the HOC structure of algolia components
export type HitRenderer = (args: { hit: Hit<any> }) => ReactNode;

export type HitsTransformer = (hits: Hit<any>[]) => ObjectWithObjectID[];

interface OurProps {
  hitRenderer: HitRenderer;
  onHits: () => void;
}

type Props = OurProps & InfiniteHitsProvided<Hit>;

/**
 * A list of search hits that requires a custom renderer.
 */
function InfiniteHits({
  hasMore,
  hits = [],
  hitRenderer,
  onHits,
  refineNext,
}: Props) {
  const { intersectionRef } = useInfiniteScroll(hasMore, refineNext);

  /**
   * Notify when there are hits. This is to prevent
   * annoying layout reflow throughout the page.
   */
  useHitsFirstReceived(hits, onHits);

  return (
    <>
      <ul
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {hits.map((hit) => {
          return (
            <li
              css={css`
                margin-bottom: 24px;
              `}
              key={hit.objectID}
            >
              {hitRenderer({ hit })}
            </li>
          );
        })}
      </ul>

      {<Intersection ref={intersectionRef} />}
    </>
  );
}

export default connectInfiniteHits<Props, Hit>(InfiniteHits);
