import { colorSteelDarker } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

type Size = "small" | "medium" | "large";

interface Props {
  active: boolean | undefined;
  backgroundColor?: string;
  className?: string;
  size?: Size;
}

const AccordionToggle: FunctionComponent<Props> = ({
  active,
  size = "medium",
  className = "",
  backgroundColor = colorSteelDarker,
}) => {
  const getSize = (size: Size) => {
    const sizes: Record<Size, string> = {
      ["large"]: "24px",
      ["medium"]: "17px",
      ["small"]: "12px",
    };
    return sizes[size];
  };

  const getWeight = (size: Size) => {
    const sizes: Record<Size, string> = {
      ["large"]: "4px",
      ["medium"]: "3px",
      ["small"]: "2px",
    };
    return sizes[size];
  };

  return (
    <div
      aria-label={`${active === true ? "close" : "open"} toggle`}
      className={className}
      css={css`
        height: ${getSize(size)};
        position: relative;
        transform: translateY(0px);
        width: ${getSize(size)};

        ::before,
        ::after {
          background-color: ${backgroundColor};
          content: "";
          opacity: 1;
          position: absolute;
          transition: all 0.2s cubic-bezier(0.46, 0.01, 0.92, 0.77);
        }

        /* Toggle vertical line */
        ::before {
          height: 100%;
          left: 50%;
          margin-left: calc(${getWeight(size)} / -2);
          top: 0;
          width: ${getWeight(size)};
        }

        /* Toggle horizontal line */
        ::after {
          height: ${getWeight(size)};
          left: 0;
          margin-top: calc(${getWeight(size)} / -2);
          top: 50%;
          width: 100%;
        }

        ${active
          ? `
        ::before {
          opacity: 0;
          transform: rotate(90deg);
        }

        ::after {
          transform: rotate(180deg);
        }
        `
          : ""}
      `}
      role={"button"}
    ></div>
  );
};

export default AccordionToggle;
