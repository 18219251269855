import { css } from "@emotion/react";

import Text from "../Text";

const FilterMenu = ({
  children,
  label,
}: {
  children: JSX.Element;
  label: string;
}) => {
  return (
    <li
      css={css`
        margin-bottom: 8px;
        list-style: none;
      `}
    >
      <Text
        as={"h4"}
        css={css`
          margin-bottom: 0.5rem;
          padding-top: 0.5rem;
        `}
        size={"xsmall"}
        weight={"semibold"}
      >
        {label}
      </Text>

      {children}
    </li>
  );
};

export default FilterMenu;
