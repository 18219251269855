import type { ComponentType, FunctionComponent } from "react";
import type {
  MenuExposed,
  MenuProvided,
  RefinementListExposed,
  RefinementListProvided,
} from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-core";
import { connectRefinementList } from "react-instantsearch-dom";

import CollapsibleFilterMenu from "./CollapsibleFilterMenu";
import type { Input } from "./FacetList";
import FacetList from "./FacetList";
import FilterMenu from "./FilterMenu";

export type Props = {
  attribute: string;
  collapsible?: boolean;
  input?: Input;
  label: string;
  minItems?: number;
};

/**
 * Displays a facet label and a list of items for that facet,
 * which the user can click on to filter search results.
 */
export const Filter: FunctionComponent<
  Props & (RefinementListProvided | MenuProvided)
> = ({
  attribute,
  collapsible = true,
  input,
  items,
  label,
  minItems = 1,
  refine,
}) => {
  // Bail if there are not enough items to display
  // but do not bail if the item(s) are selected
  if (items.length < minItems && !items.some((item) => item.isRefined)) {
    return null;
  }

  const facetList = (
    <FacetList input={input} items={items} name={attribute} refine={refine} />
  );

  return collapsible ? (
    <CollapsibleFilterMenu label={label}>{facetList}</CollapsibleFilterMenu>
  ) : (
    <FilterMenu label={label}>{facetList}</FilterMenu>
  );
};

export const ConnectedRefinementListFilter = connectRefinementList<any>(
  Filter,
) as any as ComponentType<Props & RefinementListExposed>;

export const ConnectedMenuFilter = connectMenu<any>(
  Filter,
) as any as ComponentType<Props & MenuExposed>;
