import { css } from "@emotion/react";
import type { FunctionComponent, HTMLProps } from "react";

import Icon from "../Icon";
import Text from "../Text";

interface Props extends Pick<HTMLProps<HTMLLabelElement>, "htmlFor"> {
  /**
   * A flag indicating whether or not to show the icon.
   */
  displayIcon?: boolean;

  /** An error message indicating failed validation. */
  error: any;
}

/**
 * A generic error label to be used with form inputs.
 * Displays a red warning icon, and red error text.
 */
const ErrorLabel: FunctionComponent<Props> = ({
  displayIcon,
  error,
  htmlFor,
}) => {
  return (
    <label
      css={css`
        align-items: center;
        display: flex;
        pointer-events: none;
      `}
      htmlFor={htmlFor}
    >
      {displayIcon ? (
        <Icon
          color={"red"}
          css={css`
            margin-right: 11px;
          `}
          size={"14px"}
          source={"warning"}
          yPos={"1px"}
        />
      ) : null}

      <Text as={"span"} color={"red"} size={"small"}>
        {error}
      </Text>
    </label>
  );
};

export default ErrorLabel;
