import algoliasearch from "algoliasearch/lite";

import { EnvVariableMissingError } from "./errors";

const getSearchOnlyClient = () => {
  if (
    process.env.NODE_ENV !== "test" &&
    !process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID
  ) {
    throw new EnvVariableMissingError("NEXT_PUBLIC_ALGOLIA_APPLICATION_ID");
  }

  if (
    process.env.NODE_ENV !== "test" &&
    !process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY
  ) {
    throw new EnvVariableMissingError(
      "NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY",
    );
  }

  return algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID!,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY!,
  );
};

export default getSearchOnlyClient;
