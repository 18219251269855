import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import classNames from "classnames";
import type { FunctionComponent } from "react";

import type { QueryInputProps } from "./QueryInput";
import QueryInput from "./QueryInput";

type Props = {
  className?: string;
  includeStats?: boolean;
} & QueryInputProps;

/**
 * A container showing current search stats
 * and a query input.
 */
const Query: FunctionComponent<Props> = ({
  activeWidth,
  className = "",
  inactiveWidth,
  inputAttrs,
  placeholder,
  searchIconAttrs,
  style,
}) => {
  return (
    <div
      className={classNames(className)}
      css={css`
        align-items: center;
        display: grid;

        @media (max-width: ${mediaTabletLandscape}) {
          grid-template-columns: 100%;
        }
      `}
    >
      <QueryInput
        activeWidth={activeWidth}
        inactiveWidth={inactiveWidth}
        inputAttrs={inputAttrs}
        placeholder={placeholder}
        searchIconAttrs={searchIconAttrs}
        style={style}
      />
    </div>
  );
};

export default Query;
