// Polyfill for IE11
import "intersection-observer";

import { css } from "@emotion/react";
import { forwardRef } from "react";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

// https://github.com/yannickcr/eslint-plugin-react/issues/3015
const Intersection = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div
      className={props.className}
      css={css`
        /* The transparent 1px border is necessary for
        the intersection to be observable in IE/Edge.
        Those browsers ignore an unintrusive element. */

        border: 1px solid rgba(255, 0, 204, 0);
      `}
      ref={ref}
    />
  );
});

export default Intersection;
