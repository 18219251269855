import {
  colorBlueMedium,
  colorSteelDarker,
  colorSteelDarkest,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import type { Props as IconProps } from "../Icon";
import Icon from "../Icon";
import { InputStates } from "./constants";

const stateToColorMap: Record<InputStates, string> = Object.freeze({
  [InputStates.ACTIVE_FOCUSED]: colorBlueMedium,
  [InputStates.ACTIVE_UNFOCUSED]: colorSteelDarkest,
  [InputStates.INACTIVE_UNFOCUSED]: colorSteelDarker,
});

export type Props = {
  className?: string;
  state: InputStates;
} & Pick<IconProps, "size" | "color">;

const SearchIcon = ({ className, state, ...props }: Props) => {
  return (
    <Icon
      className={className}
      css={css`
        color: ${stateToColorMap[state]};
      `}
      source={"search"}
      {...props}
      title={"Search icon"}
    />
  );
};

export default SearchIcon;
