import type { CSSProperties, FunctionComponent, ReactNode } from "react";

import Heading from "./Heading";

interface Props {
  children: ReactNode;
  /** A React style object to be applied inline. Use sparingly. */
  style?: CSSProperties;
}

/**
 * A styled h6 component, intended for MDX mapping.
 * This is probably too restrictive for other use
 * cases, and for headings you're probably better
 * off using the `Heading` component.
 */
const Heading6: FunctionComponent<Props> = ({ children, style }) => {
  return (
    <Heading as={"h6"} size={"xsmall"} style={style}>
      {children}
    </Heading>
  );
};

export default Heading6;
