import {
  borderRadiusMedium,
  colorBlueMedium,
  colorSteelDarker,
  colorSteelLighter,
  colorSteelLightest,
  colorWhite,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  HTMLProps,
} from "react";
import { useEffect, useRef, useState } from "react";
import type { SearchBoxProvided } from "react-instantsearch-core";
import { connectSearchBox } from "react-instantsearch-dom";
import { useDebounce } from "react-use";

import Icon from "../Icon";
import { DEBOUNCE_TIME, InputStates } from "./constants";
import { useSearchBoxState } from "./hooks";
import type { Props as InputProps } from "./Input";
import Input from "./Input";
import type { Props as SearchIconProps } from "./SearchIcon";
import SearchIcon from "./SearchIcon";

type PassThroughProps = Pick<HTMLProps<HTMLFormElement>, "style">;

export type QueryInputProps = {
  activeWidth?: string;
  inactiveWidth?: string;
  inputAttrs?: InputProps;
  placeholder?: string;
  searchIconAttrs?: Omit<SearchIconProps, "state">;
} & PassThroughProps;

type Props = QueryInputProps & SearchBoxProvided;

interface QueryInputState {
  borderColor: string;
  hasClearButton: boolean;
  width: string;
}

/**
 * Algolia-connected search query input.
 */
export const QueryInput: FunctionComponent<Props> = ({
  activeWidth,
  currentRefinement,
  inactiveWidth,
  inputAttrs = {},
  placeholder,
  refine,
  searchIconAttrs = {
    color: "midgray",
    size: "20px",
  },
  style,
}) => {
  const queryInputStates: Record<InputStates, QueryInputState> = Object.freeze({
    /**
     * The query input has focus and the user
     * can enter or is entering a query.
     */
    [InputStates.ACTIVE_FOCUSED]: {
      borderColor: colorBlueMedium,
      hasClearButton: true,
      width: activeWidth || "100%",
    },

    /**
     * The query input is unfocused but there
     * is input remaining from a user interaction.
     */
    [InputStates.ACTIVE_UNFOCUSED]: {
      borderColor: colorSteelLighter,
      hasClearButton: true,
      width: activeWidth || "100%",
    },

    /**
     * The default state of the query input.
     * No focus, no user input.
     */
    [InputStates.INACTIVE_UNFOCUSED]: {
      borderColor: colorSteelLighter,
      hasClearButton: Boolean(currentRefinement),
      width: inactiveWidth || "100%",
    },
  });

  const clearButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(currentRefinement);

  useEffect(() => {
    setValue(currentRefinement);
  }, [currentRefinement]);

  const { state } = useSearchBoxState({
    clearButtonRef,
    initialRefinement: currentRefinement,
    inputRef,
    refine,
  });

  const { borderColor, hasClearButton, width } = queryInputStates[state];

  const clearInput = () => {
    setValue("");
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  useDebounce(
    () => {
      refine(value);
    },
    DEBOUNCE_TIME,
    [value],
  );

  return (
    <form
      action={""}
      aria-label={"Search box"}
      css={css`
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);
        display: flex;
        height: 64px;
        justify-content: flex-start;
        margin-bottom: 40px;

        @media (max-width: ${mediaTabletLandscape}) {
          width: 100%;
        }
      `}
      noValidate={true}
      onSubmit={handleSubmit}
      role={"search"}
      style={style}
    >
      <div
        css={css`
          align-items: center;
          background-color: ${colorWhite};
          border: 1px solid ${borderColor};
          border-radius: ${borderRadiusMedium};
          box-sizing: border-box;
          display: flex;
          height: 100%;
          overflow: none;
          padding: 0 0 0 1rem;
          transition-duration: 200ms;
          transition-property: width;
          width: ${width};

          @media (max-width: ${mediaTabletLandscape}) {
            width: 100%;
          }
        `}
      >
        <SearchIcon state={state} {...searchIconAttrs} />

        <Input
          onChange={handleInputChange}
          placeholder={placeholder}
          ref={inputRef}
          spellCheck={false}
          value={value}
          {...inputAttrs}
        />

        <button
          aria-label={"Clear search"}
          css={css`
            -moz-appearance: none;
            -webkit-appearance: none;
            align-items: center;
            background-color: ${colorSteelLightest};
            border: 0 none;
            border-radius: 50%;
            cursor: pointer;
            display: ${hasClearButton && currentRefinement ? "flex" : "none"};
            height: 32px;
            justify-content: center;
            margin-right: 0.5rem;
            width: 32px;
          `}
          onClick={clearInput}
          ref={clearButtonRef}
          type={"button"}
        >
          <Icon
            css={css`
              color: ${colorSteelDarker};
            `}
            size={"10px"}
            source={"close"}
          />
        </button>
      </div>
    </form>
  );
};

export default connectSearchBox<Props & SearchBoxProvided>(QueryInput);
