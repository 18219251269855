import {
  borderRadiusMedium,
  colorWarningLight,
} from "@10xdev/design-tokens/dist";
import { css } from "@emotion/react";
import classNames from "classnames";
import type { CSSProperties, FunctionComponent, ReactNode } from "react";

interface Props {
  backgroundColor?: string;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Callout: FunctionComponent<Props> = ({
  backgroundColor = colorWarningLight,
  children,
  className,
  style,
}) => {
  return (
    <div
      className={classNames("Callout", className)}
      css={css`
        background-color: ${backgroundColor};
        border-radius: ${borderRadiusMedium};
        box-sizing: border-box;
        padding: 1rem 2rem 1.75rem 2rem;
        width: 100%;
      `}
      style={style}
    >
      {children}
    </div>
  );
};

export default Callout;
