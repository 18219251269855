import { colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Text from "../Text";
import ClearFiltersButton from "./ClearFiltersButton";
import type { ExcludeRefinements } from "./Filters";

export const Header = ({
  excludeRefinements,
  title = "",
}: {
  excludeRefinements?: ExcludeRefinements;
  title?: string;
}) => {
  return (
    <div
      css={css`
        align-items: baseline;
        border-bottom: 1px solid ${colorSteelLighter};
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5rem;
      `}
    >
      <Text as={"h3"} size={"small"} weight={"semibold"}>
        {`Filter ${title}`}
      </Text>
      <ClearFiltersButton transformItems={excludeRefinements}>
        {"Clear all"}
      </ClearFiltersButton>
    </div>
  );
};
